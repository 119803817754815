import $ from 'jquery'

type Link = {
  link: HTMLAnchorElement
  target: HTMLElement
}

$(() => {
  const getVH = (): number => {
    return document.body.scrollTop
  }

  const isActive = (link: Link): boolean => {
    return link.target.getBoundingClientRect().top <= getVH() / 2
  }
  const findTarget = (el: HTMLAnchorElement): HTMLElement | null => {
    const parts = el.href.split('#')
    const loc = location.href.split('#')[0]

    if ((parts[0].length > 0 && parts[0] != loc) || parts.length < 2)
      return null

    return document.getElementById(parts[1])
  }
  const scrollTo = (el: HTMLElement | JQuery<HTMLElement>): void => {
    $('html, body').animate({
      scrollTop: el
        ? ($(el).offset()?.top ?? 0) -
          ($('.navbar-fixed-top').outerHeight() ?? 0)
        : 0
    })
  }

  const links = $<HTMLAnchorElement>('a[href*="#"]')
    .not('[data-toggle]')
    .map(function (this: HTMLAnchorElement): Link {
      return {
        link: this,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        target: findTarget(this)!
      }
    })
    .filter(function () {
      return !!this.target
    })
    .toArray()
    .sort(function (a, b) {
      return (
        a.target.getBoundingClientRect().top -
        b.target.getBoundingClientRect().top
      )
    })

  let lastActive: Link | undefined
  function updateActive() {
    const _active = links.filter(isActive)
    const active = _active.length > 0 ? _active[_active.length - 1] : undefined

    if (active == lastActive) return
    lastActive = active

    if (active?.link) {
      $('a[href*="#"]').not(active.link).removeClass('active')
      $(active.link).addClass('active')
    }
  }
  $(document).on('scroll', updateActive)
  $(window).on('resize', updateActive)

  links.forEach(function (link) {
    $(link.link).on('click', function (e) {
      const parts1 = this.href.split('#')
      const parts2 = location.href.split('#')
      history.replaceState(null, '', parts2[0] + '#' + parts1[1])

      scrollTo(link.target)

      e.preventDefault()
    })
  })

  if (window.location.href.indexOf('#') >= 0) {
    const hash = window.location.href.split('#')[1]
    const target = $(document.getElementById(hash) || document.body).eq(0)

    if (target.length > 0) scrollTo(target)
  }
})
