import $ from 'jquery'
import 'slick-carousel'

$('.slider').slick({
  dots: true,
  arrows: true,
  infinite: true,
  speed: 500,
  adaptiveHeight: true,
  autoplaySpeed: 3000,
  autoplay: true,
  fade: false,
  cssEase: 'linear'
})

$('.hero-slider').slick({
  dots: true,
  arrows: true,
  infinite: true,
  speed: 500,
  adaptiveHeight: false,
  autoplaySpeed: 3000,
  autoplay: true,
  fade: false,
  cssEase: 'linear'
})

$('.rslider').slick({
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  autoplaySpeed: 3000,
  autoplay: false,
  fade: false,
  cssEase: 'linear'
})

$('.wadoo-rating-slider').slick({
  autoplaySpeed: 3000,
  autoplay: false,
  adaptiveHeight: true,
  prevArrow:
    '<button class="slick-prev slick-arrow btn btn-light" aria-label="Vorherige" type="button" style=""><i class="fal fa-chevron-left"></i></button>',
  nextArrow:
    '<button class="slick-next slick-arrow btn btn-light" aria-label="Nächste" type="button" style=""><i class="fal fa-chevron-right"></i></button>'
})
